@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

html, body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  line-height: 1.4;
  color: #105689;
}

.main-wrapper {
  width: 100vw;
  background-color: #fff;
  padding: 0 24px 10rem 24px;
  box-sizing: border-box;
}

.page-wrapper {
  flex: 1 0 auto;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.form-wrapper {
  grid-column: 1 / span 4;
}

#map {
  grid-column: 8 / -1;
  background-color: red;
}

.footer {
  padding: 2rem 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

@media only screen and (max-width: 992px) {
  .page-wrapper {
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: 1fr 1fr;
  }
  
  .form-wrapper {
    grid-column: 1 / span 8;
    grid-row: 1 / 2;
  }
  
  #map {
    grid-column: 1 / span 8;
    grid-row: 2 / 3;
    background-color: red;
    margin-top: 3rem;
  }
}

@media only screen and (max-width: 576px) {
  .footer {
    font-size: 14px;
  }
}


a {
  position: relative;
}

a:link, a:visited {
  color: #105689;
  text-decoration: none;
}

a:hover, a:active {
  color: #105689;
  text-decoration: none;
}


a:not(.nav-logo)::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: -4px;
  left: 0;
  background-color: #105689;
  opacity: 0.8;
  transform: scaleX(0);
  transform-origin: top left;
  transition: transform 0.2s ease;
  -webkit-transition: transform 0.2s ease;
  -moz-transition: transform 0.2s ease;
  -ms-transition: transform 0.2s ease;
  -o-transition: transform 0.2s ease;
}

a:hover::before {
  transform: scaleX(1);
}