.contact-container{
    width: 100%;
    margin: 0 auto;
}

.form-wrapper{
    width: 100%;
}

#map {
    width: 100%;
    height: 540px;
    margin-bottom: 3rem;
}

@media only screen and (min-width: 576px) {
    .contact-container{
        width: 540px;
    }
}

@media only screen and (min-width: 768px) {
    .contact-container{
        width: 720px;
    }
}

@media only screen and (min-width: 992px) {
    .contact-container{
        width: 960px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
    }

    #map{
        height: 440px;
        margin-left: 4rem;
    }
}
@media only screen and (min-width: 1200px) {
    .contact-container{
        width: 1140px;
    }

    #map{
        height: 540px;
    }
}
@media only screen and (min-width: 1400px) {
    .contact-container{
        width: 1320px;
    }
    #map{
        height: 630px;
    }
}

label {
    display: block;
}

label:not(:first-child) {
    margin: 16px 0 4px 0;
}

input, textarea {
    width: 100%;
    display: block;
    appearance: none;
    padding: 0.5em;
    border: 1px solid #8a8a8a;
    border-radius: 4px;
    background-color: #fff;
    box-sizing: border-box;
    font-size: 20px;
    color: #105689;
}

textarea {
    resize: none;
    font-size: 20px;
    font-family: 'Lato', sans-serif;
    line-height: 1.4;
}

input:focus, textarea:focus {
    outline: 0.5px solid #105689;
}

.form input:not(input[type="submit"]), .form textarea {
    width: 100%;
    box-sizing: border-box;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
}

input[type="submit"] {
    width: fit-content;
    padding: 8px 12px;
    margin: 16px 0 4px 0;
    border-radius: 4px;
    background-color: #105689;
    color: #fff;
    border: 0;
    transition: 0.3s all;
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
    -ms-transition: 0.3s all;
    -o-transition: 0.3s all;
    cursor: pointer;
}

input[type="submit"]:focus {
    border: 0;
}

input[type="submit"]:hover {
    background-color: #0067B2;
}

