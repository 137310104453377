.card-item {
    width: 100%;
}

.card-item > .cat {
    width: 18px;
    height: 18px;
    background-color: #986562;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.card-item > img {
    width: 100%;
    margin-top: 0.5rem;
}