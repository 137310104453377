.sorting-btn {
    color: #fff;
    padding: 12px 20px;
    border: none;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
}

@media only screen and (max-width: 576px) {
    .sorting-btn {
        padding: 8px 12px;
        font-size: 14px;
    }
}

/* .sorting-btn:hover {
    background-color: #4B84DA !important;
} */
