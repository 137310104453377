.header {
    width: 100%;
    padding: 0 24px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
}

.header-top {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8477984943977591) 21%, rgba(255,255,255,0.6209077380952381) 47%, rgba(255,255,255,0) 100%);
}

.header-top_logo {
    padding: 2rem 0;
}

.header-bottom {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.sorting-area {
    max-width: 996px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 0.5rem;
}