.card-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    row-gap: 2rem;
    column-gap: 1rem;
}

@media only screen and (max-width: 1200px) {
    .card-wrapper {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media only screen and (max-width: 992px) {
    .card-wrapper {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 768px) {
    .card-wrapper {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 576px) {
    .card-wrapper {
        grid-template-columns: repeat(1, 1fr);
    }
}
